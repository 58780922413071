import React from 'react'
import styled from 'styled-components'
import { BlogContainer } from '../components/BlogContainer'
import Layout from '../components/Layout'
import { Tag } from '../components/TagGroup'

const PostTitle = styled.h1`
    max-width: 800px;
    font-family: Montserrat;
    font-size: 70px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #48c9d1;
    margin-bottom: 102px;
    margin-top: 78px;
    @media screen and (max-device-width: 375px) {
        font-size: 56px;
    }
`

const PostDate = styled.div`
    font-family: Montserrat;
    color: #48c9d1;
    max-width: 190px;
    height: 22px;
    opacity: 0.6;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 12px;
`

const PostContent = styled.div`
    max-width: 700px;
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
`

const TagList = styled.ul``

export default ({ data }) => {
    const post = data.markdownRemark
    return (
        <Layout>
            <BlogContainer>
                <div>
                    <PostTitle>{post.frontmatter.title}</PostTitle>
                    <PostDate>{post.frontmatter.date}</PostDate>
                    <PostContent
                        dangerouslySetInnerHTML={{ __html: post.html }}
                    />
                </div>
                <TagList>{post.frontmatter.tags.map(Tag)}</TagList>
            </BlogContainer>
        </Layout>
    )
}

export const query = graphql`
    query BlogPostQuery($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date(formatString: "D MMMM YYYY")
                tags
            }
        }
    }
`
